<template>
  <div class="component-wrap">
    <div class="component-tit">
      {{ $t("mine.activation_key_management") }}
      <!-- 激活码管理 -->
    </div>
    <div class="filter-wrap" id="pageTop">
      <div class="search">
        <a-input-search
          v-model:value="searchVal"
          :placeholder="$t('order.please_enter_package_name')"
          style="width: 220px"
          enter-button
          allow-clear
          @search="onSearch"
        />
        <!-- 请输入培训包名称 -->
      </div>
    </div>
    <a-spin :spinning="loading">
      <template v-if="dataList.length">
        <div class="order list">
          <div class="th">
            <div class="tr">{{ $t("order.table.serial") }}</div>
            <!-- 序号 -->
            <div class="tr">{{ $t("order.table.package_name") }}</div>
            <!-- 培训包名称 -->
            <div class="tr">{{ $t("order.table.orderId") }}</div>
            <!-- 订单号 -->
            <div class="tr">{{ $t("order.table.buy_account_num") }}</div>
            <!-- 购买账号数 -->
            <div class="tr">{{ $t("order.table.remaining_code") }}</div>
            <!-- 剩余激活码 -->
            <div class="tr">{{ $t("order.table.time") }}</div>
            <!-- 时间 -->
            <div class="tr">{{ $t("order.table.operate") }}</div>
            <!-- 操作 -->
          </div>
          <div class="td" v-for="(item, index) in dataList" :key="item.orderId">
            <div class="tr">{{ (index + 1) * currentPage }}</div>
            <div class="tr">{{ item.taskName }}</div>
            <div class="tr">{{ item.orderId }}</div>
            <div class="tr">{{ item.buyNum }}</div>
            <div class="tr">{{ item.buyNum - item.useNum }}</div>
            <div class="tr">{{ dateFormat(item.createTime) }}</div>
            <div class="tr btns" @click="toDetail(item)">
              {{ $t("Pub_Tab_Detail") }}
            </div>
            <!-- 详情 -->
          </div>
        </div>
        <div class="page-Wrap">
          <a-pagination
            show-quick-jumper
            hideOnSinglePage
            :defaultPageSize="10"
            v-model:current="currentPage"
            :total="pageTotal"
            @change="pageChange"
          >
            <template #itemRender="{ type, originalElement }">
              <a class="page-a" v-if="type === 'prev'">{{ $t("cm_pre") }}</a>
              <a class="page-a" v-else-if="type === 'next'">{{
                $t("cm_next")
              }}</a>
              <renderVNode v-else :vnode="originalElement"></renderVNode>
            </template>
          </a-pagination>
        </div>
      </template>
      <a-empty
        v-else
        :image="require('@/assets/image/no_data_3.png')"
        :image-style="{ height: '186px' }"
        style="padding: 60px 0"
      />
    </a-spin>
    <div class="code-info" v-if="codeShow">
      <div class="filters">
        <div class="component-tit">
          {{ $t("mine.activation_key_management") }}
          <!-- 激活码管理 -->
        </div>
        <!-- 激活码管理 -->
        <div class="back" @click="codeShow = false">
          <RollbackOutlined />{{ $t("go_back") }}
          <!-- 返回 -->
        </div>
      </div>
      <div class="head">
        <div class="left">
          <h3 class="title">{{ activeOrder.taskName }}</h3>
          <div>
            <span>{{ $t("CM_OrderId") }}：{{ activeOrder.orderId }}</span>
            <!-- 订单号： -->
            <span
              >{{ $t("Pub_Lab_CreateTime") }}：{{
                dateFormat(activeOrder.createTime)
              }}</span
            >
            <!-- 创建时间： -->
          </div>
        </div>
        <div class="right">
          <!-- 已激活/未激活 -->
          <a-statistic
            :title="$t('order.activation_option')"
            :value="activeOrder.useNum"
            class="demo-class"
          >
            <template #suffix>
              <span class="small"
                >/ {{ activeOrder.buyNum - activeOrder.useNum }}</span
              >
            </template>
          </a-statistic>
        </div>
      </div>
      <div class="codes list">
        <div class="th">
          <div class="tr">{{ $t("order.table.serial") }}</div>
          <!-- 序号 -->
          <div class="tr">{{ $t("order.table.activation_code") }}</div>
          <!-- 激活码 -->
          <div class="tr">{{ $t("order.table.name") }}</div>
          <!-- 姓名 -->
          <div class="tr">{{ $t("order.table.account") }}</div>
          <!-- 账号 -->
          <div class="tr">{{ $t("order.table.tel") }}</div>
          <!-- 手机号 -->
          <div class="tr">{{ $t("order.table.use_time") }}</div>
          <!-- 使用时间 -->
        </div>
        <div class="box">
          <div class="td" v-for="(item, index) in codesData" :key="item.code">
            <div class="tr">{{ index + 1 }}</div>
            <div class="tr">{{ item.code }}</div>
            <div class="tr">{{ item.useUser || "" }}</div>
            <div class="tr">{{ item.useAccount || "" }}</div>
            <div class="tr">{{ item.useMobile || "" }}</div>
            <div class="tr">{{ dateFormat(item.useTime) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { codeList, orderCodes } from "@/api/user";
import { dateFormat } from "@/utils/tools";
const renderVNode = (_, { attrs: { vnode } }) => vnode;
export default {
  components: {
    renderVNode,
  },
  setup() {
    const dataList = ref([]),
      loading = ref(true),
      currentPage = ref(1),
      pageTotal = ref(1),
      searchVal = ref(),
      getList = () => {
        loading.value = true;
        codeList({
          page: currentPage.value,
          pageSize: 10,
          keyword: searchVal.value,
          type: 2,
        }).then((res) => {
          loading.value = false;
          if (res.ret === 0) {
            pageTotal.value = res.data.totals;
            dataList.value = res.data.list || [];
          }
        });
      },
      pageChange = (n) => {
        currentPage.value = n;
        getList();
      };
    getList();

    const onSearch = (value) => {
      currentPage.value = 1;
      getList();
    };

    const activeOrder = ref({});
    const codesData = ref([]);
    const codeShow = ref(false);
    const toDetail = (data) => {
      activeOrder.value = data;
      orderCodes({
        orderId: data.orderId,
      }).then((res) => {
        codeShow.value = true;
        codesData.value = res.data;
      });
    };

    return {
      dateFormat,
      dataList,
      loading,
      currentPage,
      pageTotal,
      pageChange,
      searchVal,
      onSearch,
      activeOrder,
      codesData,
      codeShow,
      toDetail,
    };
  },
};
</script>

<style lang="less" scoped>
.component-wrap {
  min-height: 100%;
  background: #fff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);

  .component-tit {
    font-size: 20px;
    line-height: 26px;
    color: #333;
    font-weight: 600;
    padding: 16px 0 12px;
  }

  .filter-wrap {
    padding-bottom: 12px;

    .search {
      padding: 5px 0;
      .mixinFlex(flex-end);

      ::v-deep(.ant-input-search) {
        padding-right: 1px;
        border-radius: 4px 0 0 4px;
      }

      ::v-deep(.ant-btn) {
        border-radius: 0 4px 4px 0;
      }
    }
  }

  .list {
    .th {
      background-color: #f2f3f5;
      color: #666666;
      .mixinFlex(space-between; center);
      padding: 16px 0;
      font-size: 14px;
    }
    .td {
      .mixinFlex(space-between; center);
      border-top: 1px solid #fff;
      padding: 17px 0;
      font-size: 14px;
      border-bottom: 1px solid #dee0e3;
    }
    .tr {
      line-height: 1.4;
      padding: 0 5px;
      word-break: break-all;
    }
    &.order {
      .tr {
        &.btns {
          color: @color-theme;
          cursor: pointer;
        }
        &:nth-child(1) {
          width: 70px;
          text-align: center;
        }
        &:nth-child(2) {
          width: 240px;
        }
        &:nth-child(3) {
          width: 180px;
        }
        &:nth-child(4) {
          width: 90px;
          text-align: center;
        }
        &:nth-child(5) {
          width: 90px;
          text-align: center;
        }
        &:nth-child(6) {
          width: 140px;
          text-align: center;
        }
        &:nth-child(7) {
          width: 70px;
          text-align: center;
        }
      }
    }
    &.codes {
      .tr {
        &:nth-child(1) {
          width: 80px;
          text-align: center;
        }
        &:nth-child(2) {
          width: 120px;
        }
        &:nth-child(3) {
          width: 160px;
        }
        &:nth-child(4) {
          width: 160px;
        }
        &:nth-child(5) {
          width: 160px;
        }
        &:nth-child(6) {
          width: 150px;
          text-align: center;
        }
      }
    }
  }
  .code-info {
    .absolute-full();
    background-color: #fff;
    padding: 30px;
    .back {
      color: #999;
      font-size: 14px;
      cursor: pointer;
      span {
        padding-right: 4px;
      }
    }
    .head {
      .mixinFlex(space-between);
      margin-bottom: 24px;
      .left {
        width: calc(100% - 110px);
        h3 {
          font-size: 18px;
          margin-bottom: 5px;
          color: #333;
        }
        span {
          font-size: 14px;
          padding-right: 20px;
          color: #666666;
        }
      }
      ::v-deep(.ant-statistic-content) {
        text-align: right;
        .small {
          font-size: 16px;
        }
      }
    }
    .box {
      height: 600px;
      overflow-y: auto;
    }
  }
}

.page-Wrap {
  .page-a {
    padding: 0 4px;
    height: 32px;
    display: inline-block;
  }

  ::v-deep(.ant-pagination-item-active) {
    border: 1px solid @color-theme;

    a {
      color: @color-theme !important;
    }
  }
}
</style>
